import { useAuth } from "../hooks/AuthProvider";
import { message } from "antd";
const useAuthRequest = () => {
  const auth = useAuth();
  
  const makeRequest = async (url, options) => {
    try {
      // Add token to headers if needed
      if (options.headers.requiresToken !== false) {
        let token = '';
        if (auth.user) {
          token = auth.user.access_token;
          options.headers['Authorization'] = `${token}`;
        }
      }
  
      // Remove the custom requiresToken property
      delete options.headers.requiresToken;
  
      // Make the initial request
      let response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/${url}`, options);
  
         // Parse the response data
      let responseData = await response.json();
      responseData.status = response.status;

      console.log(responseData.msg)

      // Handle token expiration (jwt expired case)
      if (responseData.msg === 'jwt expired') {
        let res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/refresh_token`, {
          method: 'POST',
          credentials: 'include',
        });
        let resData = await res.json();
        
        // Update the token in headers
        options.headers['Authorization'] = `${resData.access_token}`;
        localStorage.setItem('currentUser', JSON.stringify(resData));
        auth.updateUser(resData);
  
        // Retry the original request with the new token
        response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/${url}`, options);
        responseData = await response.json();
      }

            // Check if response is okay before parsing
            if (!response.ok) {
              // Attempt to parse the error response before throwing it
              let errorResponse;
              try {
                errorResponse = await response.json();
              } catch (parseError) {
                // If parsing fails, fallback to a generic error
                errorResponse = { msg: 'Failed to parse error response' };
              }
              throw new Error(errorResponse.msg || `Request failed with status ${response.status}`);
            }
        
  

  
      return responseData;
    } catch (error) {
      // Ensure the error is logged and thrown correctly
      console.error('Fetch Request Error:', error);
      throw error; // Ensure the error is passed to the calling function
    }
  };
  
  

  const getDataAPI = async (url) => {
    return await makeRequest(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  };

  const getDataAPIWithoutToken = async (url) => {
    return await makeRequest(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', requiresToken: false },
    });
  };

  const postDataAPI = async (url, post) => {
    return await makeRequest(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: post ? JSON.stringify(post) : null,
    });
  };

  const postDataAPIWithoutToken = async (url, post) => {
    const headers = post instanceof FormData
      ? {}  // No Content-Type header; allow the browser to set it
      : { 'Content-Type': 'application/json', requiresToken: false };

    return await makeRequest(url, {
      method: 'POST',
      headers: headers,
      body: post instanceof FormData ? post : JSON.stringify(post),
    });
  };
  const deleteDataAPI = async (url) => {
    return await makeRequest(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });
  };

  const patchDataAPI = async (url, post) => {
    return await makeRequest(url, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: post ? JSON.stringify(post) : null,
    });
  };

  const putDataAPI = async (url, post) => {
    return await makeRequest(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: post ? JSON.stringify(post) : null,
    });
  };

  return { getDataAPI, getDataAPIWithoutToken, postDataAPI, postDataAPIWithoutToken, deleteDataAPI, patchDataAPI, putDataAPI };
};

export default useAuthRequest;
